import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Button } from "react-bootstrap"

import { FaHeartBroken } from 'react-icons/fa';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1><FaHeartBroken />{` `} Page Not Found</h1>
    <p>That's a real heartbreaker...</p>
    <Button href="/" variant="light" size="sm">
      Back to Home
  </Button>
  </Layout>
)

export default NotFoundPage
